import { Route } from '@angular/router';
import { canActivateAdminShell, canActivateAuth, canActivateShell } from './core/auth';
import { canActivateMaintenance, canActivateApplicationByConfigStatus, hasConfigReady } from './core/config';
import { environment } from '../environments/environment';
import { GetFabricApiService, provideGetFabricApiOrigin } from '@isaia/fabric';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '',
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./features/maintenance/maintenance.routes'),
    canActivate: [hasConfigReady, canActivateMaintenance],
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.routes'),
    canActivate: [hasConfigReady, canActivateApplicationByConfigStatus, canActivateAuth],
  },
  {
    path: '',
    loadChildren: () => import('./features/shell/shell.routes'),
    canActivate: [hasConfigReady, canActivateApplicationByConfigStatus, canActivateShell],
  },
  {
    path: 'admin',
    loadChildren: () => import('./features/shell-admin/shell-admin.routes'),
    canActivate: [hasConfigReady, canActivateApplicationByConfigStatus, canActivateAdminShell],
  },
  {
    path: 'fabric/:code/:color',
    loadComponent: () => import('./features/fabric-detail/fabric-detail.component'),
    providers: [provideGetFabricApiOrigin(environment.api.getFabric), GetFabricApiService],
  },
];
