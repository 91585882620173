import { inject, Injectable } from '@angular/core';
import { RouterResolverService } from '../router/router.resolver';
import { AuthRepository } from './auth.repository';

type NavigateOrParseUrlConfig = { returnUrlTree?: boolean };

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  private readonly routerResolverService = inject(RouterResolverService);
  private readonly authRepository = inject(AuthRepository);
  private readonly urlTreeConfig = { returnUrlTree: true };
  private readonly routes = {
    logged: this.routerResolverService.order.url,
    notLogged: this.routerResolverService.auth.login().url,
  };

  public canActivateShell() {
    return this.isLoggedIn() && this.authRepository.isMtmUser()
      ? true
      : this.authRepository.isAdmin()
        ? this.redirectToAdmin()
        : this.redirectToNotLoggedRoute(this.urlTreeConfig);
  }

  public canActivateAuth() {
    return this.isLoggedIn() ? this.redirectToLoggedRoute(this.urlTreeConfig) : true;
  }

  public canActivateAdminShell() {
    return this.isLoggedIn() && this.authRepository.isAdmin() ? true : this.redirectToLoggedRoute(this.urlTreeConfig);
  }

  private isLoggedIn() {
    return this.authRepository.$isLoggedIn();
  }

  public redirectToLoggedRoute(options?: NavigateOrParseUrlConfig) {
    return this.navigateOrParseUrl(this.routes.logged, options);
  }

  public redirectToNotLoggedRoute(options?: NavigateOrParseUrlConfig) {
    const queryParams = window.location.search;
    const url = queryParams.includes('code=') ? this.routes.notLogged + queryParams : this.routes.notLogged;
    return this.navigateOrParseUrl(url, options);
  }

  public redirectToAdmin() {
    return this.navigateOrParseUrl('/admin');
  }

  private navigateOrParseUrl(url: string, options?: NavigateOrParseUrlConfig) {
    return options?.returnUrlTree ? this.routerResolverService.parseUrl(url) : this.routerResolverService.navigateByUrl(url);
  }
}

export const canActivateShell = () => inject(AuthGuard).canActivateShell();
export const canActivateAuth = () => inject(AuthGuard).canActivateAuth();
export const canActivateAdminShell = () => inject(AuthGuard).canActivateAdminShell();
