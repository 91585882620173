export interface IsaiaEntity<T extends string> {
  _entity: T;
}

export type OmitEntityProp<T> = Omit<T, '_entity'>;

export function isIsaiaEntity(value: any, entity: string) {
  if (!value) {
    return false;
  }
  return '_entity' in value && value._entity === entity;
}

export function markIsaiaEntity<T extends object, E extends string>(entity: E, value: Partial<OmitEntityProp<T>>) {
  (value as any)._entity = entity;
  return value as T & { _entity: E };
}
