import { inject, Injectable } from '@angular/core';
import { GET_FABRIC_API_ORIGIN } from './get-fabric-api-origin.token';
import { createFabric, FabricXhr } from '@isaia/entity/fabric';
import { HttpResponse } from '@isaia/entity/http';
import { map } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class GetFabricApiService {
  private readonly API_ORIGIN_GET_BY_CODE_AND_COLOR = inject(GET_FABRIC_API_ORIGIN);
  private readonly http = inject(HttpClient);

  public getFabricByCodeAndColor(payload: { color: string; code: string }) {
    const params = new HttpParams().set('fabricColor', payload.color).set('colorCode', payload.code);
    return this.http
      .get<HttpResponse<FabricXhr>>(this.API_ORIGIN_GET_BY_CODE_AND_COLOR, { params })
      .pipe(map((res) => createFabric(res.data)));
  }
}
