import { inject, Injectable } from '@angular/core';
import { NAVIGATION_END, RouterResolver } from '@isaia/router';
import { resolveAuthRoutes } from '../../features/auth/auth.routes-resolver';
import { resolveBasketRoutes } from '../../features/shell/features/basket/basket.routes-resolver';
import { resolveSessionRoutes } from '../../features/shell/features/session/session.routes-resolver';
import { resolveOrderRoutes } from '../../features/shell/features/order/order.routes-resolver';
import { resolveCustomerRoutes } from '../../features/shell/features/customer/customer.routes-resolver';
import { resolveFabricRoutes } from '../../features/shell/features/fabric/fabric.routes-resolver';
import { resolveMaintenanceRoutes } from '../../features/maintenance/maintenance.routes-resolver';
import { resolveTutorialRoutes } from '../../features/shell/features/tutorial/tutorial.routes-resolver';
import { resolveShellAdminRoute } from '../../features/shell-admin/shell-admin.routes-resolver';

@Injectable({
  providedIn: 'root',
})
export class RouterResolverService extends RouterResolver {
  public readonly auth = resolveAuthRoutes(this);
  public readonly basket = resolveBasketRoutes(this);
  public readonly session = resolveSessionRoutes(this);
  public readonly order = resolveOrderRoutes(this);
  public readonly customer = resolveCustomerRoutes(this);
  public readonly fabric = resolveFabricRoutes(this);
  public readonly maintenance = resolveMaintenanceRoutes(this);
  public readonly navigationEnd$ = inject(NAVIGATION_END);
  public readonly tutorial = resolveTutorialRoutes(this);
  public readonly admin = resolveShellAdminRoute(this);
}
