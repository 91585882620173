// eslint-disable-next-line @nx/enforce-module-boundaries
import { markIsaiaEntity, IsaiaEntity, isIsaiaEntity } from '@isaia/entity';
import { FabricXhr } from './fabric.xhr';

const ENTITY = 'FABRIC';

export interface Fabric extends IsaiaEntity<typeof ENTITY> {
  id: number;
  code: string;
  color: string;
  availability: number | null;
  bunch: string;
  category: number;
  season: string;
  backInStock: string | null;
  createdAt: string;
  updatedAt: string | null;
  range: string;
  forcedStatus: boolean | null;
  seasonCode?: string;
  fabricComposition?: string;
  fabricGroup?: string;
  weight?: string;
  image: string;
}

const FABRIC_NO_IMAGE = 'fabric-no-image';

export function createFabric(options: Partial<FabricXhr>): Fabric {
  return markIsaiaEntity(ENTITY, {
    id: options.id,
    code: options.fabricCode,
    color: options.colorCode,
    availability: options.availability,
    bunch: options.bunchId,
    category: options.fabricCategoryCode,
    season: options.seasonName,
    seasonCode: options.seasonCode,
    backInStock: options.backInStock,
    createdAt: options.createdAt,
    updatedAt: options?.updatedAt,
    fabricComposition: options.composition,
    fabricGroup: options.group,
    range: options.range,
    forcedStatus: options.forcedStatus,
    weight: options.weight,
    image: options.image || FABRIC_NO_IMAGE,
  });
}

export function isInstanceOfFabric(value: unknown): value is Fabric {
  return isIsaiaEntity(value, ENTITY);
}
