import { EmptyUrlResolverParams, RouterResolver } from '@isaia/router';

export const resolveShellAdminRoute = (resolver: RouterResolver) => {
  return resolver.createFeatureResolver('admin', (rootPath) => {
    const fabric = resolver.createFeatureResolver(rootPath + '/fabric', (adminFabricRoot) => {
      return {
        list: (options?: EmptyUrlResolverParams) => {
          const url = resolver.createUrlTree([adminFabricRoot, 'list'], options);
          return resolver.createUrlNavigableData(url);
        },
      };
    });

    return { fabric };
  });
};
